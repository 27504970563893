.badgesContainer{
    overflow: hidden;
    position: relative;
}

.badgesWrap{
    padding: 50px;
    background: black;
    height: 100%;
}
.badgeItem{
    margin: 200px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.badge{
    -moz-transition: all 2s ease-in;
    -o-transition: all 2s ease-in;
    -webkit-transition: all 2s ease-in;
    transition: all 2s ease-in;

    width: 20%;
    position: absolute;
    margin-left: 70%;
    
}
.desc{
    -moz-transition: all 3s ease-in;
    -o-transition: all 3s ease-in;
    -webkit-transition: all 3s ease-in;
    transition: all 3s ease-in;

    text-align: center;
    color: black;
    width: 80%;
    margin-left: 0%;
}
.badgeSpan{
    color: #1EBBB5;
}
#lastBadge{
    margin-bottom: 50px;
}

.badgeEdge{
    background-image: url(./images/stroke6.png);
    background-size: 100% 100%;
    height: 100px;
    width: 100%;
}

/* mediaquery */
@media only screen and (max-width: 1024px){
   .badgeItem{
       margin: 100px 0px;
   }
}
@media only screen and (max-width: 768px){
   .desc{
       font-size: 100%;
   }
 }
 @media only screen and (max-width: 450px){
    .badgesWrap{
        padding: 20px;
    }
    .badgeItem{
        margin: 50px 0px;
    }
  }