  
.workContainer{
    margin: 50px 0px;
}
.carouselWrap{
    display: flex;
    padding: 50px;
}
.carouselTxt{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.carouselTitle{
    /* text-align: center; */
    font-size: 30px;
    margin: 20px 0px;
}

.carouselImgDiv{
    width: 60%;
    display: flex;
    justify-content: center;
}
.carouselImg{
    width: 85%;
}