.frontAboutContainer{
    display: flex;
    padding: 50px;
    margin: 80px 0px;
}
.aboutWrap{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.miniHead{
    color: #00bfb7;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
}
.mainP{
   font-size: 34px;
   margin-bottom: 10px;
}
.littleP{
    font-size: 17px;
}
.svgDiv{
    /* padding: 10px; */
    width: 48%;
}
@media only screen and (max-width: 768px){

    .frontAboutContainer{
        display: flex;
        flex-direction: column;
        padding: 50px;
        margin: 80px 0px;

    }
    .aboutWrap{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        
    }
    .miniHead{
        color: #00bfb7;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
    }
    .mainP{
       font-size: 34px;
    }
    .littleP{
        font-size: 15px;
        width: 50%;
        font-style: italic;
    }

    .svgDiv{
        padding: 10px;
        width: 82%;
        margin: 40px auto 0px auto;
       

    }
  }
  @media only screen and (max-width: 600px){
    .frontAboutContainer{
        display: flex;
        flex-direction: column;
        padding: 50px;
        margin: 80px 0px;

    }
    .aboutWrap{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        
    }
    .miniHead{
        color: #00bfb7;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
    }
    .mainP{
       font-size: 30px;
    }
    .littleP{
        font-size: 14px;
        width: 70%;
        font-style: italic;
    }

    .svgDiv{
        padding: 10px;
        width: 82%;
        margin: 40px auto 0px auto;
       

    }
  }
  @media only screen and (max-width: 450px){
    .frontAboutContainer{
        display: flex; 
        flex-direction: column;
        padding: 30px;
        margin: 80px 0px;

    }
    .aboutWrap{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        
    }
    .miniHead{
        color: #00bfb7;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
    }
    .mainP{
       font-size: 26px;
    }
    .littleP{
        font-size: 11px;
        width: 75%;
        font-style: italic;
    }

    .svgDiv{
        padding: 10px;
        width: 82%;
        margin: 40px auto 0px auto;
       

    }
  }