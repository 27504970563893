.processContainer{
    padding: 30px 0px;
    margin: 50px 0px 0 0;
    background: #e9e9ec;
}
.bigHead{
    padding-top: 50px; 
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    font-size: 45px;
    margin: 0;
}
.bigHeadLine{
    width: 10%;
    border: solid 2px #8a133b;
}
.processCardsWrap{ 
    display: flex;
    padding: 50px; 
    
}
.processCardsDiv{
    width: 33.34%;
    margin: 0px 20px;
}
.processCard{
    /* border: solid .5px gray; */
    display: flex;
    flex-direction: column;
    position: relative;
    /* background-image: url("./images/undraw3.svg");
    background-size: contain;
    background-repeat: no-repeat; */
}
.processCard3::after {
    content: "";
    background: url(./images/undraw3.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;   
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

  }
  .processCard2::after {
    content: "";
    background: url(./images/undraw4.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;   
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
  }
  .processCard1::after {
    content: "";
    background: url(./images/undraw1.svg);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.15;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;   
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
.phase{
    text-align: center; 
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}

.phaseHead{
    font-size: 35px;
    color: #02bfb6;
    text-align: center;
    margin: 10px 0px;
}   
.phaseTxt{
    margin: 20px 30px;
    color: rgb(37, 37, 37);
    text-align: center;
    font-size: 18px;
}

@media only screen and (max-width: 768px){
    .processCardsDiv{
        width: 33.34%;
        margin: 0px 10px;
    }
    .phase{
        text-align: center;
        padding: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 17px;
    }
    .phaseHead{
        font-size: 30px;
        color: #02bfb6;
        text-align: center;
        margin: 10px 0px;
    }   
    .phaseTxt{
        margin: 20px 30px;
        color: rgb(37, 37, 37);
        text-align: center;
        font-size: 17px;
    }
  }
  @media only screen and (max-width: 600px){
    .processCardsWrap{ 
        display: flex;
        padding: 30px;
        flex-direction: column;
        align-items: center;
        
    }
    .processCardsDiv{
        width: 100%;
        
    }
    .processCard1::after{
        width: 90%;
        background-position: center center;
    }
    .processCard2::after{
        width: 90%;
        background-position: center center;
    }
    .processCard3::after{
        width: 90%;
        background-position: center center;
    }
    .phase{
        text-align: center;
        padding: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
    }
    .phaseHead{
        font-size: 28px;
        color: #02bfb6;
        text-align: center;
        margin: 8px 0px;
    }   
    .phaseTxt{
        
        color: rgb(37, 37, 37);
        text-align: center;
        font-size: 16x;
    }
  }
  @media only screen and (max-width: 450px){
    .processCardsWrap{ 
        display: flex;
        padding: 30px;
        flex-direction: column;
        align-items: center;
        
    }
    .processCardsDiv{
        width: 100%;
        
    }
    /* .processCard1::after{
        width: 99%;
        margin-left: auto;
        margin-right: auto;
        
    }
    .processCard2::after{
        width: 99%;
        margin-left: auto;
        margin-right: auto;
        
    }
    .processCard2::after{
        width: 99%;
        margin-left: auto;
        margin-right: auto;
    } */
    .phase{
        text-align: center;
        padding: 10px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 17px;
    }
    .phaseHead{
        font-size: 30px;
        color: #02bfb6;
        text-align: center;
        margin: 6px 0px;
    }   
    .phaseTxt{
        
        color: rgb(37, 37, 37);
        text-align: center;
        font-size: 17px;
    }
  }