
/* --------------------------------------------------
Carousel
-------------------------------------------------- */
.rev{
    transition: all .3s ease-in;
    background: rgba(233, 233, 236, 0.9);
}
.rev.revSlideshow {
    width: 100%;
    height: 300px;
    position: relative;
    /* background: rgba(255, 255, 255, 0.3); */
     
  }
  .rev.revSlideshow .slides {
    width: 100%;
    height: 300px; 
    position: relative;
    z-index: 0;
    top: 35%;
    
  }
  .rev .slides li {
    width: 70%;
    height: 350px;
    position: absolute;
    display: none;
    left: 14%;
  }
  
  .heresWhat{
    font-size: 30px;
    width: 100%;
    text-transform: uppercase;
    position: absolute;
    text-align: center;
  }
  .rev .prev,
  .rev .next {
    
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 3em;
    font-weight: bold;
    padding: 10px 5px;
    opacity: 0.8;
    transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -webkit-transition: opacity 0.5s;
    
  }
  .rev .prev:hover,
  .rev .next:hover {
    opacity: 1;
    cursor: pointer;
  }
  .rev .prev {
    left: 0%;
    display: block !important;
    
  }
  .rev .next {
    right: 0%;
    display: block !important;
   
  }
  .rev .nav {
    position: absolute; 
    bottom: 0px;
    /* right: 20px; */
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    
  }
  .rev .nav li {
    float: left;
    margin-right: 15px;
    width: 10px;
    height: 10px;
    border: 2px solid gray;
    border-radius: 10px;
    transition: background 0.5s;
    -o-transition: background 0.5s;
    -moz-transition: background 0.5s;
    -webkit-transition: background 0.5s;
  }
  /* .rev .nav li:hover {
    background-color: #fff;
    cursor: pointer;
  } */
  .rev .active-cd {
    background-color: teal;
  }
  
  
  .rev .arrow {
    position: absolute;
    padding: 0.2rem; /* Arrow size */
            box-shadow: 1px -1px 0 1px teal inset;
    -webkit-box-shadow: 2px -2px teal inset;
    border: solid transparent;
    border-width: 0 0 2rem 2rem;
    transition: 0.2s;
  }
  
  .rev .arrow:hover {
            box-shadow: 2px -2px 0 2px #DB018C inset;
    -webkit-box-shadow: 4px -4px #DB018C inset;
  }
  
  .rev .arrow-right { transform:rotate(225deg) }
  .rev .arrow-left  { transform:rotate(45deg) }
  
  /* End Carousel Styles */
  
  #new-signup form .actions {
    margin-left: 2.3%;
  }
  
  /* Main Content */
  .rev-content {
    margin: 40px 0;
    position: relative;
  }
  
  .rev-banner {
    margin-bottom: 40px;
  }
  
  .left-section {
    float: left;
    width: 55%;
  }
  
  .right-section img{
    margin-bottom: 40px;
    max-width: 100%;
  }
  
  .right-section {
    float: left;
    width: 40%;
    margin-left: 4%;
  }
  
  .about-barbizon a {
    display: inline-block;
    background: #db018c;
    margin: 20px 0;
    border-radius: 3px;
    padding: 10px 15px;
    color: #fff;
  }
  
  .about-barbizon a:hover {
   color: #ff00a3;
  }
  
  .about-barbizon h2 {
    color: #db018c;
    margin-bottom: 0;
  }
  
  .about-barbizon h3 {
    margin-top: 0;
  }
  
  .about-barbizon p {
    border-top: 1px solid #db018c;
    padding-top: 20px;
  }
  
  li {
    list-style: none;
  }
  
  ul {
    padding: 0;
  }
  
  
  .carouselDesc{
    width: 100%;
    /* position: absolute; */
    right: 0%;
  }
  
  .reviewPiece{
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
  }
  
  @media only screen and (max-width: 768px){
    .rev .slides li {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 450px){
    .heresWhat{
      font-size: 20px;
    }
    .rev .prev {
      left: 25%;
    }
    .rev .next {
      right: 25%;
    }
    .rev .prev,
    .rev .next {
      top: 50px;
    }
  }