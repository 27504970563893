
/* --------------------------------------------------
Carousel
-------------------------------------------------- */
.ga.slideshow {
  width: 100%;
  height: 500px; 
  position: relative;
  background: white;
  
}
.ga.slideshow .slides {
  width: 100%;
  height: 500px;
  position: relative;
  z-index: 0;
  top: 150px;
  margin: inherit;
  
}
.ga .slides li {
  width: 50%;
  height: 30vw;
  position: absolute;
  display: none;
  
}

.ga .prev,
.ga .next {
  
  position: absolute;
  top: 20px;
  z-index: 1;
  font-size: 3em;
  font-weight: bold;
  padding: 10px 5px;
  opacity: 0.8;
  transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  
}
.ga .prev:hover,
.ga .next:hover {
  opacity: 1;
  cursor: pointer;
}
.ga .prev {
  left: 40%;
  display: block !important;
  
}
.ga .next {
  right: 40%;
  display: block !important;
 
}
.ga .nav {
  position: absolute;
  bottom: 10px;
  /* right: -1.3%; */
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  
}
.ga .nav li {
  float: left;
  margin: 10px;
  width: 10px;
  height: 10px;
  border: 2px solid gray;
  border-radius: 10px;
  transition: background 0.5s;
  -o-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -webkit-transition: background 0.5s;
}
/* .ga .nav li:hover {
  background-color: #fff;
  cursor: pointer;
} */
.ga .active-cd {
  background-color: teal;
}


.ga .arrow {
  position: absolute;
  padding: .2rem; /* Arrow size */
          box-shadow: 1px -1px 0 1px teal inset;
  -webkit-box-shadow: 2px -2px teal inset;
  border: solid transparent;
  border-width: 0 0 2rem 2rem;
  transition: 0.2s;
}

.ga .arrow:hover {
          box-shadow: 2px -2px 0 2px #DB018C inset;
  -webkit-box-shadow: 4px -4px #DB018C inset;
}

.ga .arrow-right { transform:rotate(225deg) }
.ga .arrow-left  { transform:rotate(45deg) }

/* End Carousel Styles */

#new-signup form .actions {
  margin-left: 2.3%;
}

/* Main Content */
.ga-content {
  margin: 40px 0;
  position: relative;
}

.ga-banner {
  margin-bottom: 40px;
}

.left-section {
  float: left;
  width: 55%;
}

.right-section img{
  margin-bottom: 40px;
  max-width: 100%;
}

.right-section {
  float: left;
  width: 40%;
  margin-left: 4%;
}

.about-barbizon a {
  display: inline-block;
  background: #db018c;
  margin: 20px 0;
  border-radius: 3px;
  padding: 10px 15px;
  color: #fff;
}

.about-barbizon a:hover {
 color: #ff00a3;
}

.about-barbizon h2 {
  color: #db018c;
  margin-bottom: 0;
}

.about-barbizon h3 {
  margin-top: 0;
}

.about-barbizon p {
  border-top: 1px solid #db018c;
  padding-top: 20px;
}

li {
  list-style: none;
}

ul {
  padding: 0;
}


.carouselDesc{
font-size: 18px;
  top: 10px;
  left: 96%;
  position: absolute;
  
}

/* .carouselPiece{
  display: flex;
  flex-direction: row;
} */

@media only screen and (max-width: 768px){
  .ga .slides li { 
    flex-direction: column;
  }
  .carouselDesc{
    font-size: 16px;
    top: -4px;
  }
}
@media only screen and (max-width: 650px){
  .carouselDesc{
    font-size: 14px;
    top: -5px;
  }
}
@media only screen and (max-width: 600px){
  .ga .prev {
    left: 35%;
    display: block !important;
    
  }
  .ga .next {
    right: 35%;
    display: block !important;
   
  }
  .carouselDesc{
    font-size: 14px;
      top: 130px;
      left: -8%;
      position: absolute;
      text-align: center;

      
    }
    .ga .slides li {
      width: 50%;
      height: 30vw;
      position: absolute;
      display: none;
      left: 29%;
      top: -50px;
    }
   
}
@media only screen and (max-width: 550px){
  .carouselDesc{
    font-size: 12px;
    top: 130px;
      left: -8%;
      position: absolute;
      text-align: center;
  }
   
}
@media only screen and (max-width: 450px){
  .carouselDesc{ 
    font-size: 12px;
    top: 160px;
      left: -8%;
      position: absolute;
      text-align: center;
  }
  .ga .prev {
    left: 29%;
    display: block !important;
    
  }
  .ga .next {
    right: 29%;
    display: block !important;
   
  }
  .ga .slides li {
    width: 70%;
    height: 50vw;
    position: absolute;
    display: none;
    left: 21%;
    top: -50px;
  }
}