html,
body {
  /* background-color: black; */
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Open Sans Condensed', sans-serif;
  background: #f5f5f5;
  
}

.transitionFast{
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}