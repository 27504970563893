.footEdge{
    background-image: url(./images/stroke7.png);
    background-size: 100% 100%;
    height: 100px;
    width: 100%;
}
.footer{
    padding: 50px 100px;
    display: flex;
    justify-content: space-between;
    color: #e6e6e6;
    background-color: black;
}
.footHead{
    margin:0;
}
.lilmargin{
    margin: 10px 0px;
}

.footHr{
    width: 50%;
    border: #1EBBB5 solid 1px;
}
.optDiv{
    text-align: center;
    display: flex;
    flex-direction: column;
}
.info{
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}
.socialDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.socialIcon{
    margin: 0px 10px;
    height: 50px;
    width: 50px;
}
.socialIcon:hover{
    filter: brightness(150%);
}
.copy{
    color: gray
}
.footLogoDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#tagline{
    color: #1EBBB5;
}
.logoBot{
    margin-left: 10px;
}

@media only screen and (max-width: 1024px){
    .footLogoDiv{
        margin: 20px 0px;
    }
    .footer{
        padding: 50px 50px;
    }
  }

  @media only screen and (max-width: 768px){

    .studio{
        display: none;
    }
    .footer{
        flex-direction: column-reverse;
    }
    .footLogoDiv{
        align-items: center;
    }
  
  }

  @media only screen and (max-width: 450px){
      
    #footLogo{
        width: 100%;
        height: 50px;
    }
  }