#hayesBeer{
    margin: 0;
    padding-top: 125px;
    margin-bottom: 0px;
    background-color: black;
    color: rgb(255, 255, 255);
    text-align: center;
    text-shadow: 1px 1px #818181;
    font-size: 50px;
    font-family: 'Open Sans Condensed', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.workHeader{
    text-align: center;
    margin: 50px 100px;
}

.slider {
    border: #818181 solid 4px;
    position: relative;
    grid-area: d;
    height: 75vh;
    margin: 5px 50px 5px 50px;
}
.slide {
	position: absolute;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	opacity: 0;
}
.active{
    opacity: 1;
}

#words{
    text-align: center;
    text-decoration: underline black;
}
#words:hover{
    cursor: pointer;
}
#wordLink{
 text-decoration: none;
}

@media only screen and (max-width: 768px){
    #hayesBeer{
        font-size: 35px;
    }
   }

   @media only screen and (max-width: 500px){
    #hayesBeer{
        font-size: 25px;
    }
    .workHeader{
        margin: 50px 20px;
    }
    .midTxtDiv{
        font-size: 100%;
    }
    .slider{
        height: 50vh;
    }
    #words{
        font-size: 100%;
    }
   }