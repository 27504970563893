#navbar {
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;

    overflow: hidden;
    color: whitesmoke;
    height: 125px;
    background-color: #000000c0;
    transition: 0.4s;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navLogoDiv{
    margin: 0px 20px;
}
.homeMenu{
   margin: 0px 20px;
}
.homeNav{
    font-size: 18px;
    letter-spacing: .5px;
    text-decoration: none;
    color: rgb(207, 207, 207);
    margin: 0px 10px;
}

.homeNav:hover{
    color: white;
    filter: brightness(300%);
    text-decoration: line-through;
}
#homeId{

}
#aboutId{
    
}

/* mobile nav */
#menu{
    display: none;
    margin-right: 20px;
}

.modal{
    transition: all .5s ease-in;
    /* display: none; */
    position: fixed; 
    z-index: 99; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 0%; 
    overflow: auto; 
    background-color: rgba(0, 0, 0, 0.90);
    color: white;
}
.modalContent{
    display: flex;
    flex-direction: column;
}
.close{
    font-size: 75px;
    align-self: flex-end;
    margin-right: 20px;
}
.mobileMenu{
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 300px;
    justify-content: space-around;
}
.mobileNav{
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    font-size: 25px;
}
.mobile{
    margin: 20px 0px;
}