.emailWrap{
    margin: 100px 0px;
    /* background: #02bfb6; */
}
.mailHeader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.talk{
  font-weight: 300;
  font-size: 40px;
    color: rgb(43, 43, 43);
    margin: 30px 0px;
}
.mailBtns{
  margin: 30px 0px;
}
.emailBtn{
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    background: #02bfb6;
    color: white;
    border: solid 2px black;
    padding: 15px 10px;
    text-transform: uppercase;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
.emailBtn:hover{
    cursor: pointer;
    background: #1EBBB5;
    color: rgb(105, 105, 105);
}

#contact{
  text-decoration: none;
}