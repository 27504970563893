.frontCenter{
    animation: imageAnimation 10s ;
    background: black;
    height: 100vh;
    width: 100%;
    margin: 0;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@keyframes 
imageAnimation {  0% {
 animation-timing-function: ease-in;
 opacity: 0;
}
 8% {
 animation-timing-function: ease-out;
 opacity: 1;
}
17%{
    opacity: 1;
}
 25% {
 opacity: 1;
}
 100% {
 opacity: 1;
}
}

.logoDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}
#frontLogo{
    width: 70%;
    height: 70%;
}

.downDiv{
    color: rgb(204, 204, 204);
    position: absolute;
    z-index: 97;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    text-align: center;
    letter-spacing: 4px;
    /* text-transform: capitalize; */
}
.downDiv:hover .down .downtxt{
    cursor: pointer;
    filter: brightness(400%);
}
#arrow-wrapper {
    width: 60px;
    height: 60px;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
}
.arrow-border {
    position: relative;
    width: 60px;
    height: 60px;
    border: 2px solid #c5c5c5;
    /* border: 2px solid #e09202; */
    border-radius: 50%;
}
.arrow-border:hover{
    cursor: pointer;
    filter: brightness(300%);
}
.arrow {
    position: absolute;
    bottom: 24px;
    width: 20px;
    height: 20px;
    border-top: 3px solid  #c5c5c5;
    border-right: 3px solid  #c5c5c5;
    right: 19px;
}
  
.down {
    -moz-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}  

.pulse {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    position: absolute;
    margin: 0;
    animation: pulsate 2s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
    /* box-shadow: 0 0 1px 2px #89849b; */
    box-shadow: 0 0 1px 2px #1EBBB5;
    animation-delay: 2s;
}
  
@keyframes pulsate {
    0% {
        transform: scale(0.9, 0.9);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        transform: scale(1.3, 1.3);
        opacity: 0;
    }
}

.frontEdge{
    position: absolute;
    bottom: -80px;
    background-image: url(./images/stroke6.png);
    background-size: 100% 100%;
    height: 100px;
    width: 100%;
}