.aboutContainer{
    overflow: hidden;
}
.adjPicDiv{
    transition: all .3s ease-in;
    position: fixed;
    display: flex;
    justify-content: center;
}
.adjPic{
    opacity: 1;
    z-index: -2;
    transition: all 1s ease-in;
    width: 15%;
    height: inherit;
}


.stroke{
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;  
}

#aboutTitle{
    margin: 0;
    padding-top: 135px; 
    margin-bottom: 0px;
    background-color: black;
    color: rgb(255, 255, 255);
    text-align: center;
    text-shadow: 1px 1px #351d1d;
    font-size: 50px;
    font-family: 'Open Sans Condensed', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.name{
    text-transform: uppercase;
}
.skillset{
    color: rgb(85, 85, 85);
    text-transform: lowercase;
    
}
.short{
    background: url(../styles/stroke.png);
    background-size: 25% 25%;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat; 
}
.long{
    background: url(../styles/stroke.png);
    background-size: 50% 25%;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
}

.whatWeDo{
    color: rgb(85, 85, 85);
}

.years{
    display: none;
    font-size: 20px;
    /* color: rgb(85, 85, 85); */
    color: #02bfb6;
}



.profiles{
    display: none;
    position: relative;
    height: 100%;
}
.nameTags{
    display: flex;
    justify-content: space-around;
}
.experience{
    width: 100%;
    padding: 30px;
    text-align: center;
}

.brandsContainer{
    padding: 50px;
    transition: all 1s ease-in;
    opacity: 0;
    position: relative;
}

.brandsCardsWrap{
    width: 100%;
}
.brandLogosDiv{
    width: 100%;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
}
.logoEmboss{
    width: 50%;
}

.aboutEmailWrap{
    height: 50vh;
    width: 100%;
    /* background: #02bfb6; */
    opacity: 0;
    z-index: -1;
    transition: all 1s ease-in;
}

.aboutEmailBtn{
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    background: #02bfb6;
    /* background: grey; */
    color: white;
    border: solid 2px black;
    padding: 15px 10px;
    text-transform: uppercase;
    font-size: 15px;
    display: flex;
    align-items: center;
}
.buffer{
    content: "";
    height: 100vh;
    display: flex;
    align-items: center;
}


/* mediaqueries */

@media only screen and (max-width: 450px){
    .name{
        font-size: 18px;
    }
    .experience{
        padding: 5px;
    }
    .whatWeDo{
        display: none !important;
    }
    .years{
        font-size: 16px;
    }
    .brandLogosDiv{
        flex-direction: column;
    }
    .adjPic{
        height: 400px;
        width: 30%;
    }
    .brandsContainer{
        padding: 0;
    }
}